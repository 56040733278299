@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .ir-pixelated {
        image-rendering: pixelated;
        image-rendering: -moz-crisp-edges;
        image-rendering: crisp-edges;
    }
}

@font-face {
    font-family: 'Visby';
    src: url('../../public/fonts/VisbyBold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
}

html {
    font-size: 3.5vw;
}

@media screen and (min-width: 640px) {
    html {
        font-size: 2.5vw;
    }
}

@media screen and (min-width: 1024px) {
    html {
        font-size: 26px;
    }
}

model-viewer {
    width: inherit;
    height: inherit;
    object-fit: contain;
}